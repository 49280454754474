.modal {
    .booking-details {
        .table {
            border-collapse: separate;
            
            tbody {
                tr {
                    th{
                        background-color: #337ab7;;
                        color: #fff;
                        letter-spacing: 1px;
                    }

                    th, 
                    td {
                        text-align: center;
                        border-right: 1px solid #3379b7af;
                    }

                    th:first-child {
                        border-left: 1px solid #3379b7af;
                    }

                    &:first-child {
                        td, 
                        th {
                            border-top: 1px solid #3379b7af;
                        }
                        td:last-child {
                            border-radius: 0 5px 0 0;
                        }
                    }

                    &:last-child {
                        th,
                        td {
                            border-bottom: 1px solid #3379b7af;
                        }
                        
                        th:first-child {
                            border-radius: 0 0 0 5px;
                        }

                        td:last-child {
                            border-radius: 0 0 5px 0;
                        }
                    }
                }
            }
        }
    }
}