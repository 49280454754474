.chat-window {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: clamp(240px, 25vw, 300px);
    top: 35px;
    z-index: 10000;
    box-shadow: 0px 5px 15px 2px #0000003b;

    .chat-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 40px;
      border-radius: 5px 5px 0 0;
      background-color: #fff;
      box-shadow: 0px 5px 10px 0px #0000003b;
      cursor: move;
      z-index: 150;

      .messages-header {
        font-size: clamp(18px, 1.8vw, 20px);
        color: #41a5f0;
        font-weight: bold;
        margin-left: 20px;
      }
      .close{
        font-size: clamp(15px, 1.8vw, 20px);
        color: #41a5f0;
        margin: 5px 7px 10px 10px;
        align-self: flex-start;
        opacity: 1;
        cursor: pointer;
      }
    }
    .chat {
      width: 100%;
      background: #FFF;
      color: #302533;

      .chat-history {
        height: 330px;
        padding-bottom: 15px;
        overflow-x: auto;
        touch-action: pan-y !important;

        .chat-entry {
          max-width: 80%;
          margin: 15px 10px 0;
          padding: 10px;
          border: #cecece solid 1px;
          border-radius: 20px 20px 20px 5px;
          background-color: #d4c2d8;
          
          &.current-user {
            text-align: right;
            margin-left: auto;
            background-color: #845EC2;
            color: #fff;
            border-radius: 20px 20px 5px 20px;
          }

          &---message {
            margin-bottom: 5px;
            font-size: clamp(13px, 1vw, 17px);
          }
          
          &--name {
            font-size: clamp(8px, .9vw, 10px);

            i {
              margin: {
                right: 10px;
                top: 10px;
              }
            }
          }
        }
      }

      .input-group {
        width: 100%;
        max-width: 200px;

        .input-group-addon {
          padding: 0;
        }

        button {
          height: auto;
        }
      }
    }
    .chat-entry-form {
        display: flex;
        justify-content: space-evenly;
        padding: 10px 0;
        
        input {
          border-radius: 30px;
          width:60%;
          background-color: #eeeeee;
          font-size: clamp(14px, 1.4vw, 16px);
          border: none;
          box-shadow: none;
        }
        button  {
          background-color: #41a5f0;
          color: #fff;
          font-size: clamp(14px, 1.4vw, 16px);
          font-weight: bold;
          border-radius: 20px;
          width:30%  !important;
        }
    }
}