.time-drag {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: 0 auto;

  input {
    background-color: transparent;
  }

  input[type=range] {
    -webkit-appearance: none;
    margin: 20px 0;
    width: 100%;
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    animate: 0.2s;
    background: #03a9f4;
    border-radius: 25px;
  }
  input[type=range]::-webkit-slider-thumb {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0 0 4px 0 rgba(0,0,0, 1);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7px;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #03a9f4;
  }
  .time-range-bar{
    width: 500px;
    position: relative;
    padding-right: 5px;

    #range_value{
      position: absolute;
      top: -50%;
    }
    #range_value {
      width: 50px;
      height: 30px;
      line-height: 20px;
      text-align: center;
      background: #03a9f4;
      color: #fff;
      font-size: 15px;
      display: block;
      position: absolute;
      left: 50%;
      transform: translate(-30%, 0);
      border-radius: 6px;
    }
    #range_value:before{
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-top: 5px solid #03a9f4;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      margin-top: -1px;
    }
  }

  .time-reset {
    margin: 0 20px;
    height: 100%;
    border: none;
    cursor: pointer;
    border: none;
    background-color: transparent;
    
    .fa-circle {
      font-size: clamp(15px, 1.5vw, 20px);
      border: none;
    }

    .active {
      transition: .5s all ease-out;
      color:rgb(0, 160, 0);
      text-shadow: 0 0 7px rgb(0, 119, 0);
    }
  }
  
}
