@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}

p, div, td, {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

@mixin text_rotate($deg) {
  @include transform(rotate(-#{$deg}deg) translateY(-50%) translateX(-50%));
}


$status-red: #b30000bf;
$status-green: #75af57bf;
$status-orange: #f2bd71; //rgba(213, 134, 0, 0.75);
$status-yellow: #f2f254bf;
$bg-color: #3f3f3f;
$blue: #41a5f0;

*::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 2px;
	background-color: #F5F5F5;
}

*::-webkit-scrollbar {
	width: 8px;
  height: 8px;
	background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb {
	border-radius: 2px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #78bff5;

  &:hover {
    background-color: #3a95db;
  }
}

body {
  font-family: 'Poppins', sans-serif !important;
  padding: 0;
  margin: 0;

  .btn:focus,
  .btn:focus-visible,
  button:focus,
  button:focus-visible,
  a:focus,
  a:focus-visible {
    outline: none !important;
  }

  p, div li, th, td {
    color: #3f3f3f;
  }

  // background: url(images/bg2.png);
  background-color: rgb(243, 243, 243) !important;

  &.decuba {
    .rhost {
      .layout {
        .level {
          //background: url(Css/images/rdc-bg.jpg) repeat;
          //background: #606060;
          //background: #EEE url(Css/images/decuba.png) no-repeat center center;
        }
      }
    }
  }

  &.revolution {
    .rhost {
      .layout {
        .level {
          //background: url(Css/images/rdc-bg.jpg) repeat;
          //background: #606060;
          background: #EEE url(images/revolution.png) no-repeat center center;
        }
      }
    }
  }

  .rhost {
    width: 100%;
    height: 100%;

    .main-content {
      padding: .5vw .5vw 0;
      height: 100%;
      display: flex;
      justify-content: space-between;
      width: 100%;      
      background-color: #fff;


      .layout {
        width: 70%;
        height: 100%;

        .tab {
          border: 1px solid #41a4f0;
          background-color: #ecf1f5;
        }

        .level {
          position: relative;
          margin: 10px auto 0;

          img {
            width: 100%;
          }


          .ipad-scroll {
            td, th {
              color: $bg-color;
            }
          }

          .tb-table {
            position: absolute;
            border: rgb(126, 126, 126) solid 1px;
            background-color: #FFF;
            text-align: center;
            font-size: 8px;
            font-weight: bold;
            // background-repeat: no-repeat;
            // background-position: center center;
            // background-size: 20%;

            &.no-walk-in {
              opacity: 0.5;
            }

            &.late {
              background: #b31117;

              .table-status-text {
                color: #FFF;
              }
            }

            &.active-booking {
              border: #08beff solid 1px;
              box-shadow: 0px 0px 15px -3px #0797cc;

              &.status-1 {
                background: #0D3349;
              }

              &.status-60 {
                .table-status-text {
                  color: #FFF;
                }
              }

              &.overdue, &.late {
                background: #b31117;

                .table-status-text {
                  color: #FFF;
                }
              }
            }

            .table-status-text {
              position: relative;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              align-items: center;
              margin: 0 auto;
              height: 100%;
              color: #000;
              font-size: clamp(8px, .8vw, 10px);
              line-height: 0.8vw;

              progress {
                width: 80%;
                border-radius: 5px;
                -webkit-appearance: progress-bar;
              }

              progress[value] {
                /* Reset the default appearance */
                -webkit-appearance: none;
                appearance: none;
                width: 80%;
                height: 10px;
                border-radius: 5px;
              }

              progress::-webkit-progress-bar {
                background-color: #eee;
                border-radius: 5px;
                box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
              }

              progress::-webkit-progress-value {
                // background-image: -webkit-linear-gradient(left, #337ab7, #41a5f0);
                background-color: #549CF6;
                border-radius: 5px;
              }


              .table-name {
                word-break: break-word;
                line-height: 10px;
              }
            }


            > div {
              position: absolute;
              top: 0;
              width: 200px;
              height: 200px;
              background: #FFF;
              border: #000 solid 1px;
              border-radius: 4px;
              z-index: 999;
            }

            &.selected {
              box-shadow: 0 0 0 2px $blue inset;
            }
          }
        }

        .rectangle, .square {
          border-radius: 5px;
        }

        .circle {
          -moz-border-radius: 50px;
          -webkit-border-radius: 50px;
          border-radius: 50px;

          &.tb-table {
            p {
              margin-top: 10%;
              width: 70%;
              text-align: center;
              margin-left: 15%;
            }
          }
        }

        .rectangle-horizontal, .rectangle-vertical {
          border-radius: 5px;
        }

      }
    }
  }
}



.modal {
  &.show {
    background: rgba(0, 0, 0, 0.5);
    z-index: 99999999;
  }

  .modal-dialog {
    margin-top: 45px;
  }
}

.partypro-tabs-holder {
  @include clearfix;

  > ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    @include clearfix;

    li {
      float: left;
      margin: 0;
      padding: 0;

      p {
        margin: 0;
      }

      a {
        display: block;
        background: #41a5f0;
        padding: 10px;
        text-decoration: none;
        color: #FFF;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        margin-right: 5px;
      }

      &.pull-right {
        margin-right: -5px;
        margin-left: 5px;
      }

      &.active {
        a {
          background: #337ab7;
          font-weight: bold;
        }
      }
    }
  }

 
}

.modal {
  overflow: scroll !important;
}

.modal-layout {
  .layout {
    width: 100%;
  }
}


.run-list {
  background-color: #e2f4ff;
  overflow: auto;
  position: relative;

  .btn-group {
    width: 50%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    margin: 3vw auto;

    .btn {
      width: 100%;
    }

    .btn-primary {
     font-weight: bold;
    }
  }

  .empty-list {
    position: absolute;
    top: 50%;
    left: 10%;
    width: 80%;
    text-align: center;
    margin: 0 auto;
    font-size: 4vw;
    opacity: 0.3;
    letter-spacing: 2px;
  }
}

.table-wrap {
  overflow-y: auto;
  height: clamp(450px, 80%, 750px);

  .table {
    position: relative;
    width: 95%;
    margin: 0 auto;
    text-align: center;
    border-collapse: separate;
    // table-layout: fixed;
    
    
    th, td {
      vertical-align: middle;
    }

    thead  {
      position: sticky;  
      z-index: 2;
      top: 0;
      
      tr {
        background: #337ab7;
        box-shadow: 0px 7px 6px -1px #3379b752;
        
        th {
          border-right: 1px solid #ffffff4d;
          text-align: center;
          color: #fff;
          letter-spacing: 1px;
          font-size: clamp(8px, 1vw, 15px);
          padding: clamp(5px, 1vw, 10px);

          &:last-child {
            border-right: 0;
          }
        }

      }
    }
    
    tbody {  
      background-color: #fff;
      font-size: clamp(9px, .9vw, 30px);

      .table-time{
        display: flex;
        flex-direction: column;

        .time-divider {
          transform: rotate(90deg);
        }
      }
      
      td {
        border-bottom: 1px solid #ddd;
        border-right: 1px solid #8080804d;
        padding: clamp(5px, .5vw, 7px);
        height: clamp(50px, 5vw, 90px);


        &:first-child {
          border-left: 1px solid #8080804d;
        }
      }
      
      tr:last-child{
        td:last-child{
          border-radius: 0 0 5px 0;
        }

        td:nth-last-child(8n){
          border-radius: 0 0 0 5px;
        }
      }
    }
  }
}



.input-addon-button {
  font-size: 9px !important;
}

.add-walk-in, .edit-reservation {
  .row {
    margin-bottom: 10px;
  }
}

.ipad-scroll {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.list-view-booking {
  background: #41a5f0;
  position: absolute;
  opacity: 0.8;
  padding: 4px;
  color: #FFF;
  border: #cecece solid 1px;
  border-radius: 4px;
  z-index: 99;
  overflow: hidden;
  cursor: pointer;
  display: flex !important;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;

  .drag {
    float: left;
    cursor: grab;
    font-size: 13px;
    margin: 0 3px;
    width: 5%;
    overflow: hidden;
  }

  .res-info {
    float: left;
    width: 90%;
    margin: 0 3px;

    .fa-user {
      float: right;
      font-size: 13px;
      margin: 0 3px;
    }
  }
}

.in-table-booking-list {
  th {
    width: 4vw;
  }
}

.in-table-booking {
  background: #8edef5;
  border: #000 solid 1px;
  width: 1vw;
  color: #FFF;
  position: absolute;
  left: 4vw;
}

.level-loading {
  background: #000;
  z-index: 200;
  opacity: 0.9;
}

input[type=range] {
  -webkit-appearance: none;
  margin: 18px 0;
  width: 100%;
}

input[type=range]:focus {
  outline: none;
}

html {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

body, html {
  overscroll-behavior: none;
}


input[type=range].time-travel::-webkit-slider-runnable-track {
  background: orange;
}


.btn {
  &.btn-sm {
    font-size: 1.2vw;
  }
}

#modal {
  .input-group-addon {
    font-size: 1.1vw;
  }

  .modal-header{
    h5 {
      float: left;
      font-size: clamp(20px, 2vw, 30px);
      font-weight: bold;
      letter-spacing: 1px;
      text-transform: uppercase;
      vertical-align: middle;
    }

    button {
      float: right;
      vertical-align: middle;
      margin-top: 0;
      line-height: 0;
      
      .fa-times {
        font-size: clamp(20px, 2vw, 25px);
        vertical-align: middle;
      }
    }
  }
}

.partypro-tabs-holder {
  .partypro-tab-link {
    a {
      font-size: clamp(13px, 1.3vw, 15px);
    }
  }
}

 body {
  height: 100%;
  overflow: hidden;
}

#holder {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
}

@media (min-device-width: 1025px) and (max-device-width: 4000px) {
  .layout {
    .level {
      .tb-table {
        p {
          font-size: 0.6vw;
        }

        span {
          top: -18px;
        }
      }
    }
  }
}

.modal {
  .modal-header {
    button {
      span {
        font-size: 1.4vw;
      }
    }
  }
}


@-webkit-keyframes animate-stripes {
  100% {
    background-position: -100px 0px;
  }
}

@keyframes animate-stripes {
  100% {
    background-position: -100px 0px;
  }
}

.modal-body {
  .layout {
    progress {
      margin-top: 1vh;
    }
  }
}

.list-view {
  overflow: hidden;
  .time-indicator {
    z-index: 999;
    border-left: red solid 1px;
    height: 100%;
    position: absolute;
    width: 1px;
  }
  .list-view-booking {
    span {
      font-size: 1em;
      font-weight: bold;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.list-view-table {
  th, td {
    padding: 4px !important;
  }

  &.zoom-level-0 {
    th, th span, td, td span {
      font-size: 12px;
    }
  }

  &.zoom-level-1 {
    th, th span, td, td span {
      font-size: 13px;
    }
  }

  &.zoom-level-2 {
    th, th span, td, td span {
      font-size: 14px;
    }
  }

  &.zoom-level-3 {
    th, th span, td, td span {
      font-size: 15px;
    }
  }

  &.zoom-level-4 {
    th, th span, td, td span {
      font-size: 16px;
    }
  }

  &.zoom-level-5 {
    th, th span, td, td span {
      font-size: 17px;
    }
  }
}

.react-loading {
  display: block;
  margin-top: 30px;
  width: 100%;
}

.react-loading i {
  font-size: 80px;
  text-align: center;
  margin: 0 auto;
  display: block;
}

@keyframes shake {
  0% {
    transform: translate(1px);
  }
  50% {
    transform: translate(-2px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.layout-detail-item {
  &.moving {
    .tb-table {
      /* Start the shake animation and make the animation last for 0.2 seconds */
      animation: shake .3s;

      /* When the animation is finished, start again */
      animation-iteration-count: infinite;
    }
  }

  .actions {
    font-family: 'Poppins', sans-serif;
    padding: 5px;
    background: #FFF;
    position: absolute;
    width: 200px;
    height: auto;
    border: #7070707c solid 1px;
    border-radius: 7px;
    z-index: 9999;

    .btn {
      width: 100%;
      margin: 5px 0;
    }

    .table-info {
      border-bottom: #7070707c solid 1px;
      padding: 3px 0;

      span {
        font-size: 11px;
      }

      .table-name {
        float: left;
      }

      .table-covers {
        float: right;
      }
    }

    .open-reservation {
      background-color: #ff9900;
      color: #fff;
    }

    .move {
      background-color: #0055FF;
      color: #fff;
    }

    .complete-reservation, .add-walk-in {
      background-color: #23B913;
      color: #fff;
    }

    .open-reservation:hover {
      background-color: #ec8e00;
    }

    .move:hover {
      background-color: #004ce4;
    }

    .complete-reservation:hover,
    .add-walk-in:hover {
      background-color: #20a311;
    }

    .popup-status {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #000;

      .popup-status-title {
        font-size: 1.2vw;
        margin: 0;
      }

      .popup-status-text {
        font-size: 1.4vw;
        font-weight: bold;
      }
    }

    .change-status {
      font-size: 1.2vw;
      margin: 10px 0 5px;
    }

  }

  .layout-detail-future-booking {
    display: block;
    width: 100%;
    border: #cecece solid 1px;
    text-align: center;
    padding: 4px;
    margin: 1vw 0;
    font-size: 1.1vw;
  }

  .table-popup-reservations {
    max-height: 300px;
    overflow: auto;
  }
}

body#ui-datepicker-div {
  z-index: 100 !important;
}

.modal {
  z-index: 99999;
}

#modal {
  .date-picker-modal {
    .modal-dialog {
      max-width: 400px;

      .modal-content {
        .modal-header, .modal-footer {
          display: none;
        }

        .modal-body {
          text-align: center;

          div {
            margin: 0 auto;
          }
        }

        
      }
    }
  }

  .alert {
    overflow-y: auto;
    max-height: 100px;
  }

  .alert-danger {
    box-shadow: 0px 4px 10px -3px #960000;
    -webkit-box-shadow: 0px 3px 7px -3px #960000;
  }
  .alert-info {
    box-shadow: 0px 4px 10px -3px #0391d8;
    -webkit-box-shadow: 0px 3px 7px -3px #0391d8;
  }

  .input-group.covers {
    .btn-group {
      .btn {
        height: clamp(30px, 2vw, 40px);
      }
      .btn:first-of-type {
        border-radius: 3px 0 0 3px !important;
      }
    }

    .covers-group {
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-end;

      .btn-group {
        button {
          padding-top: 0;  
          padding-bottom: 0;
          background-color: #fff;
          border: 1px solid #cccccc8a;
        }

        button:hover, 
        button:focus, .btn.active {
          background-color: #FF8B5D;
          border: 2px solid #ff6f37e3;
          border-radius: 2px;
          color: #fff;
        }
      }

      .custom-covers {
        width: 20%;

        input {
          height: clamp(30px, 2vw, 40px);
          border-radius: 3px;
        }
      }
    }
  }

  .form-group {
    .select2 {
      .selection {
        li {
          display: flex;
          align-items: center;
          background-color: #41a5f0;
          color: #fff;
          border: 1px solid #337ab7;
          height: clamp(30px, 2vw, 40px);

          span {
            font-size: clamp(20px, 1.7vw, 25px);
            color: #fff;
          }

          span:hover, 
          span:focus, 
          span:active {
            color: #FF8B5D;
            transition: all .1s ease-in-out;
          }
        }
      }
    }
  }
}


.list-view {
  &.zoom-level-0 {
    .table-name, .table-time, .level-header {
      font-size: 12px;
    }

    .table-name {
      width: 25px;
    }

    .table-time {
      width: 35px;
    }
  }

  &.zoom-level-1 {
    .table-name, .table-time, .level-header {
      font-size: 13px;
    }

    .table-name {
      width: 30px;
    }

    .table-time {
      width: 40px;
    }
  }

  &.zoom-level-2 {
    .table-name, .table-time, .level-header {
      font-size: 14px;
    }

    .table-name {
      width: 35px;
    }

    .table-time {
      width: 45px;
    }
  }

  &.zoom-level-3 {
    .table-name, .table-time, .level-header {
      font-size: 15px;
    }

    .table-name {
      width: 40px;
    }

    .table-time {
      width: 50px;
    }
  }

  &.zoom-level-4 {
    .table-name, .table-time, .level-header {
      font-size: 16px;
    }

    .table-name {
      width: 45px;
    }

    .table-time {
      width: 55px;
    }
  }

  &.zoom-level-5 {
    .table-name, .table-time, .level-header {
      font-size: 17px;
    }

    .table-name {
      width: 50px;
    }

    .table-time {
      width: 60px;
    }
  }

  .ipad-scroll {
    overflow: scroll;

    .list-view-level {
      min-width: 100%;
      display: table;
      border-collapse: separate;
      border-spacing: 0;

      .level-header {
        display: table-row;
        width: 100%;
        background: #cecece;
        font-weight: bold;
        padding: 5px 0;
        text-indent: 20px;
      }

      .level-tables {
        width: 100%;

        .level-table-row {
          width: 100%;
          border-bottom: #cecece solid 1px;

          .table-cell-wrapper {
            float: left;
          }

          .table-name {
            font-weight: bold;
            padding: 0;
            margin: 0;
            float: left;
            text-align: left;
          }

          .table-time {
            padding: 5px 0;
            margin: 0;
            float: left;
            //display: table-cell;
            vertical-align: middle;
            color: #b4b4b4;
          }
        }
      }
    }
  }
}

.reservation-message-send {
  .btn-group {
    li {
      width: 500px;
      overflow: hidden;
      height: auto;
      border-bottom: #cecece solid 1px;

      &:last-child {
        border-bottom: none;
      }

      a {
        max-width: 500px;
        white-space: normal;
      }
    }
  }
}

.add-track-trace {
  .input-group {
    width: 100%;
    margin-bottom: 5px;

    .input-group-addon {
      width: 200px;
    }
  }

  .btn-group {
    width: 100%;

    .btn {
      width: 50%;
    }
  }
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}

@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

@mixin text_rotate($deg) {
  @include transform(rotate(-#{$deg}deg) translateY(-50%) translateX(-50%));
}

.layout {
  @for $i from 1 through 360 {
    .rotate-#{$i} {
      @include rotate($i);
    }
  }

  .level {
    .tb-table {
      @for $i from 1 through 360 {
        &.rotate-#{$i}::before {
          @include rotate($i);

          p, div {
            @include rotate(360 - $i);
          }
        }
      }
    }
  }
}

.react-datepicker-popper {
  padding-left: 3vw;
  z-index: 2;
}

.react-datepicker {
  .react-datepicker__day {
    padding: 4px;
    margin: 2px;
  }

  .react-datepicker__header {
    .react-datepicker__day-name {
      padding: 3px;
    }
  }

  .react-datepicker__day-name, 
  .react-datepicker__day, 
  .react-datepicker__time-name {
    width: 2.2vw;
    font-size: clamp(1vw, 1.1vw, 1.5vw);
  }
  .react-datepicker__current-month {
    font-size: clamp(.9vw, 1vw, 1.3vw);
  }
}