.modal {
  .modal-body {
    padding-bottom: 0;
    padding-top: .5vw;
  }

  .reservation {
    .res-info-wrapper {
      display: flex;
      justify-content: space-between;
      padding: 1vw 0;
      border: 1px solid #3379b7af;
      background-color: #e2f4ff;
      border-radius: 0 5px 5px;

      .covers-group {
        label {
          width: 100%;
        }

        .btn-group {
          button {
            padding-top: 0;  
            padding-bottom: 0;
            background-color: #fff;
            border: 1px solid #cccccc8a;
          }

          button:hover, 
          button:focus {
            background-color: #FF8B5D;
            border: 2px solid #ff6f37e3;
            border-radius: 2px;
            color: #fff;
          }
        }

        .custom-covers {
          width: 35% !important;
          margin: 0 0 0 auto;

          .form-control {
            border-color: #cccccc8a;
          }
        }
      }
      .res-input-group {
        width: 50%;
        padding: 0 0 0 1vw;
      }

      .divider {
        border-right: 2px solid #3379b7af;
      }

      .reservation-info {
        width: 45%;
        padding: 0 1vw 0 0;

        .res-info-header{
          display: flex;
          justify-content: space-around;
          padding-bottom: 1vw;
          border-bottom: 1px solid #ff6f37e3;
          
          span {
            font-size: clamp(12px, 1.2vw, 20px);
            font-weight: bold;
            letter-spacing: 1px;
          }
        }

        .res-info-box {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          background-color: #fff;
          border-radius: 3px;
          overflow: hidden;


            p {
              font-weight: bold;
              font-size: clamp(18px, 1.8vw, 20px);
              text-align: center;
              box-shadow: 0px 7px 6px -1px #3379b752

            }

          .levels-content {
            border-left: 1px solid #cccccc8a;
            border-right: 1px solid #cccccc8a;
          }

          .tables-content, 
          .levels-content, 
          .areas-content {
            display: flex;
            flex-direction: column;
            text-align: center;
            max-height: 200px;
            overflow-y: auto;
            padding: .7vw;

            span {
              font-size: clamp(12px, 1.2vw, 20px);
              margin-bottom: .5vw;
              border-bottom: 1px solid #cccccc8a;
            }

            span:last-child {
              border-bottom: none;
            }
          }
        }
      }

      .form-group{
        margin-bottom: .5vw;

        .form-control {
          border-color: #cccccc8a;
        }
      }
      .res-duration {
        margin-top: 1vw;
        display: flex;
        justify-content: space-between;
  
        .res-start, .res-length{
          width: 25%
        }
  
        .res-status {
          width: 35%;
        }
      }
  
      .res-location {
        .res-tables {
          .select2-selection {
            max-height: 100px;
            overflow-y: auto;
            border-color: #aaaaaa50;
          }
        }
  
        .level-area {
          display: flex;
          justify-content: space-between;
  
          .res-levels, .res-areas {
            width: 45%;
  
            .select2-selection {
              max-height: 100px;
              overflow-y: auto;
              border-color: #aaaaaa50;
            }
          }
        }
      }
    }

    .reservation-actions {
      display: flex;
      justify-content: space-between;
      margin: .5vw 0;

      .btn-info > .caret {
        margin-left: 8px;
      }

      .dropdown-menu {
        padding-bottom: 0;
        top: 35px;

        li {
          padding: 0 .5vw;
          margin-bottom: .5vw;
          border-bottom: none;

          button {
            height: fit-content;
            border-radius: 4px;
            border: 2px solid #41a5f0;
            color: #fff;
            background-size: 300% auto;
            background-image: -webkit-gradient(linear, left top, right top, from(#53328C), color-stop(40%, #EE1E91), color-stop(60%, #EE1E91), to(#53328C));
            background-image: linear-gradient(to right, #53328C 0%, #EE1E91 40%, #EE1E91 60%, #53328C 100%);
            -webkit-transition: background ease 750ms;
            transition: background ease 750ms;
          }

          button:hover, 
          button:focus,
          button:active {
            background-position: right center;
          }
        }
      }

      .btn-group {

        &
        .seat-reservation{
          background-color: #ff9900;
          color: #fff;
          
          
          &:hover, 
          &:focus,
          &:active {
            background-color: #ec8e00;
          }
        }

        .no-show {
          background-color: #ce161d;
          color: #fff;

          &:hover, 
          &:focus,
          &:active {
            background-color: #b31117;
          }
        }

        .complete-reservation {
          background-color: #23B913;
          color: #fff;

          &:hover, 
          &:focus,
          &:active {
            background-color: #20a311;
          }
        }
      }
    }

    .table-wrap {
      max-height: 300px;
      
      .table {
        width: 100%;

        tbody {
          font-size: clamp(14px, 1.4vw, 18px);
        }
      }
    }
  }
}