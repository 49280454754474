.booking-search {
  position: relative;
  list-style: none;
  border-radius: 5px;
  z-index: 1;
  display: flex;
  align-content: center;

  #search {
    z-index: 1;
    border: none;
    margin: 0 auto;
    box-shadow: none;
    width: 100%;
    height: 3vw;
    font-size: 1.4vw;
    text-align: center;
    border: none;
    border-radius: 0 5px 5px 0;
    padding: 0 0 0 10px;
    text-align: start;
    &:-webkit-autofill,
    &:-webkit-autofill:hover, 
    &:-webkit-autofill:focus, 
    &:-webkit-autofill:active{
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
  }
  
  .fa-search {
    display: flex;
    justify-items: center;
    align-items: center;
    z-index: 1;
    color: #959595;
    font-size: 20px;
    background-color: #fff;
    border-radius: 5px 0 0 5px;
    padding-left: 5px;
  }

  @keyframes opacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .search-results {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    display: flex;
    flex-direction: column;
    padding: 40px 5px 5px;
    border-radius: 5px;
    overflow: auto;
    max-height: 500px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 1px 10px 0px #00000059;
    -webkit-box-shadow: 0px 0px 14px 0px #00000059;
    -moz-box-shadow: 0px 0px 14px 0px #00000059;
    -webkit-animation: opacity .15s linear;
    animation: opacity .15s linear;

    .search-group{
      width: 100%;
      display: grid;
      background-color: #fff0e8;
      border: 1px solid transparent;
      padding: clamp(5px, .5vw, 7px) 10px;
      border-radius: 5px;
      justify-content: space-between;
      grid-template-areas: "covers   null    time"
                            "tables  tables  tables"
                            "name    name    name";
      &:hover, 
      &:focus {
        background-color: #fff;
        border: 1px solid #f86d36;
        transition: all .2s ease-in-out;
      }

      &:not(:last-child) {
        margin-bottom: .5vw;
      }

      .search-covers{
        margin-bottom: .5vw;
        grid-area: covers;
      }
      .search-time{
        margin-bottom: .5vw;
        grid-area: time;
      }
      .search-tables{
        margin-bottom: .5vw;
        grid-area: tables;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        height: auto;
        background: #4ca7ec;
        border-radius: 3px;
        padding: clamp(5px, .5vw, 7px) 5px;
        letter-spacing: 1px;
        grid-area: tables;
        white-space: normal;

        p {
          color: #FFF;
          padding: .2vw .5vw
        }
      }
      .search-name{
        margin-bottom: .5vw;
        grid-area: name;
        border-bottom: 1px solid #80808078;
      }
    }
  }
}