.right-col-reservation-row {
  .customer-type {
    width: 100%;
    float: left;
    display: block;
    font-style: italic;

    img.customer-type-icon {
      max-width: 1.5vw;
      margin-right: 5px;
    }

  }

  .res-info {
    background: #fff;
  }

  .res-info:hover, 
  .res-info:focus {
    background-color: #ffece4;
    border: 1px solid #FF8B5D;
    transition: all .2s ease-in-out;
  }
}