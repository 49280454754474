.header {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  padding: .5vw 0;
  background-color: #52A4EE;  
  border-bottom: 1px solid #337AB7;
  color: #fff;

  .bar-name {
    height: 3vw;
    width: clamp(250px, 30%, 400px);
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-weight: bold;
    margin: 0;
    background-color: #337AB7;
    border-radius: 5px;

    .status-indicator {
      min-width: 15px;
      min-height: 15px;
      border-radius: 20px;

      &.offline {
        background: #fa4444;
      }
      
      &.online {
        background: #17ff17;
      }
    }

    .bar-and-user {
      max-width: 90%;
      display: flex;
      justify-content: center;
      flex-direction: column;

      .bar {
        font-weight: bold;
        letter-spacing: 1px;
      }

      .user {
        font-weight: normal;
      }

      .bar,
      .user {
        color: #fff;
        margin: 0;
        font-size: 1.1vw;
      }
    }

    .btn-group {
      font-size: 1.7vw;

      .fa-pencil-alt, 
      .fa-desktop {
        cursor: pointer;
      }
      .fa-desktop {
        margin-right: 1vw;
      }
    }

    &.change-bar {
      display: flex;
      justify-content: space-evenly;
      flex-direction: row;
      min-width: 20%;
      max-width: 30%;
      
      
      .bar-dropdown {
        max-width: 60%;
        
        .input-group {
          .form-control {
            height: 100% !important;
            padding: 0;
            text-align: center;

            option:not(:first-child) {
              text-align: start;
            }
          }
        }

        i {
          font-size: 12px;
        }
      }

      .buttons {
        .fa-check,
        .fa-times {
          font-size: 1.4vw;
          cursor: pointer;
        }
        
        .fa-check {
          margin-right: 1vw;
        }
      }
    }
  }

  .session {
    height: 3vw;
    width: 30%;
    display: flex;
    align-items: center;
    color: #000;

    .prev-day, 
    .next-day {
      height: 100%;
      width: 2.5vw;
      background-color: #337AB7;
      color: #fff;
      border: none;
      
      i {
        font-size: 1.4vw;
      }
    }

    .prev-day {
      border-radius: 5px 0 0 5px;
    }
    .next-day {
      border-radius: 0 5px 5px 0;   
    }

    .react-datepicker-wrapper {
      height: 100%;
      
      .react-datepicker__input-container {
        height: 100%;
        font-size: 1.2vw;
        
        .date-picker {
          width: 100%;
          height: 100%;
        }
      }

      button {
        border: none;
        padding: 5px;
        background-color: #fff;
        height: 100%;
        font-weight: bold;
        height: 100%;
      }
      
      .not-today {
        background-color: #ff743c;
        color: #fff;
      }
    }
  }

  .wait-time {
    height: 3vw;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    width: 12%;
    background-color: #fff;
    border-radius: 5px;

    .fa-facebook-messenger, 
    .fa-sync {
      font-size: 2vw;
      cursor: pointer;
    }
    .fa-facebook-messenger {
      color: #41a5f0;
    }
    .fa-sync {
      color: #FF8B5D;
    }
    .fa-sync:active {
      animation-name: spin;
      animation-duration: .5s;
      animation-timing-function: ease-out;
    }

    @keyframes spin {
        from {
            transform:rotate(0deg);
        }
        to {
            transform:rotate(360deg);
        }
    }

    .warnings-holder {
      position: relative;
      
      .fa-exclamation-triangle {
        font-size: 2vw;
        cursor: pointer;
      }
      .disabled {
        color: #636363b7;
      }
      .danger {
        color: #ff0000;
      }

      .warnings {
        position: absolute;
        top: 4vh;
        right: 0;
        min-width: 25vw;
        max-height: 500px;
        overflow-y: scroll;
        z-index: 9999;

        .alert {
          margin: 0;

          .warning-buttons{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0;

            .ignore, .edit {
              width: 80%;
            }
            
            .ignore {
              margin-top: .5vw;
            }
          }
        }
      }
    }

    .fa-facebook-messenger{
      position: relative;
      span {
        position: absolute;
        text-align: center;
        line-height: 15px;
        right: -5px;
        top: -5px;
        background: red;
        border-radius: 20px;
        width: 15px;
        height: 15px;
        color: #FFF;
        font: {
          size: .9vw;
          family: 'Poppins', sans-serif;
        }
      }
    }
  }

  .search {
    width: 25%;
  }
}


// ==============
// Media Querries
//===============

@media screen and (min-width: 1200px) and (max-width: 1950px){
  .header {
    height: 2.5vw;
    padding: 1vw 0;
  
    .bar-name,
    .session,
    .wait-time,
    .search #search{
      height: 2vw;
    }

    .search {
      width: 28%;
    }

    .bar-name {
      .bar-and-user {
        .bar, .user {
          font-size: .7vw;
        }
      }

      .btn-group {
        font-size: 1.2vw;
      }
    }

    .session {
      .react-datepicker__input-container {
        button {
          line-height: .2vw;
        }
      }
    }

    .wait-time {
      .fa-facebook-messenger, 
      .fa-sync {
        font-size: 1.4vw;
     }

     .fa-facebook-messenger{
      span {
        line-height: 20px;
        right: -8px;
        top: -8px;
        width: 20px;
        height: 20px;
        font-size: .8vw;
      }
    }

     .warnings-holder {
       .fa-exclamation-triangle {
         font-size: 1.4vw;
       }
     }
    }

    .search input {
        font-size: 1.2vw; 
    }
  }
}

@media screen and (min-width: 1950px) {
  .header {
    .wait-time {
     .fa-facebook-messenger{
      span {
        line-height: 25px;
        right: -8px;
        top: -8px;
        width: 25px;
        height: 25px;
        font-size: .9vw;
      }
    }
  }
}
}