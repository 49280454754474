.modal {
  .add-walk-in {

    .covers {
      label {
        width: 100%;
      }
    }

    .form-group, .row {
      margin-bottom: 0;
    }

    .length-holder {
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-end;

      .form-group {
        width: 80%;
        float: left;

        select {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      .end {
        font-weight: bold;
        width: 20%;
        text-align: center;
        height: 34px;
        line-height: 25px;
        padding: 5px;
        background: #e4e4e4;
        font-size: clamp(14px, 1.4vw, 18px);
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border: #aaa solid 1px;
      }

      .input-group-addon {
        height: 40px !important;
        padding: 0 10px;
      }
    }

    .reservation-actions {
      margin: 10px -15px;
    }
  }
}