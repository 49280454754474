.right-col {
  border-radius: 5px;
  width: 29%;
  margin: 0 auto;
  height: 600px;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  overflow-x: hidden;

  .waiting-list-action {
    padding: 2px 8px;
  }

  .waiting-list {
    margin: 0 10px;
    padding: 0;
    list-style-type: none;

    .res-info {
      width: 100%;
      border: #cccccc54 solid 1px;
      border-radius: 5px;
      background: #fff;
      box-shadow: none;
      margin-bottom: 0.5vw;

      &:hover, &:focus {
        background-color: #ffece4;
        border: 1px solid #FF8B5D;
        transition: all .2s ease-in-out;
      }

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: clamp(10px, 1vw, 15px);

        .covers {
          i {
            padding: 0 4px;
          }
        }

        .name {
          margin: 0 auto;
        }

        .open {
          padding: 2px 8px;
          background: #41a5f0;
          color: #FFF;
          border-radius: 2px;
          cursor: pointer;
        }
      }
    }
  }

  .next-slots {
    margin: 0 10px;
    padding: 0;
    list-style-type: none;

    .res-info {
      background: #fff;
    }

    .res-info:hover, .res-info:focus {
      background-color: #ffece4;
      border: 1px solid #FF8B5D;
      transition: all .2s ease-in-out;
    }

    .free-tables-group {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .tables {
        width: 80px;
        background: #41a5f0;
        color: #FFF;
        border-radius: 2px;
        overflow: hidden;

        &.status-1 {
          background: #75af57bf;
        }

        &.status-3, &.status-0 {
          background: #f2bd71;
        }
      }
    }

    .btn {
      font-size: clamp(10px, 1vw, 15px);
    }
  }

  .bookings-summary {
    height: fit-content;
    background-image: none;
    background-color: #e2e2e2;
    padding: 20px 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 14px 1px #36363650 inset;

    .bookings-date, .bookings-title {
      text-align: center;
    }

    .bookings-date {
      font-weight: bold;
      margin-top: 0;
    }

    hr {
      border: 0;
      height: 2px;
      width: 60%;
      position: relative;
      margin: 30px auto;
      background: #03a9f4;
    }

    .booking-content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-items: center;
      text-align: center;

      .booking-type {
        font-size: 1.2vw;
        font-weight: bold;
        margin-bottom: 10px;
      }

      p {
        margin-bottom: 5px;
        font-size: 1.2vw;
      }
    }
  }

  .create-booking {
    width: 90%;
    margin: 1vw auto;

    .btn {
      width: 100%;
    }
  }

  .collapse-container {
    width: 95%;
    margin: 0 auto 1vw;

    .collapse-header {
      border-radius: 5px 5px 0 0;
      color: #FFF;
      background: #41a5f0;
      padding: 1vh 1vw;
      font-size: clamp(13px, 1.3vw, 20px);

      a {
        float: right;
        display: block;
        padding: 0 1vw;

        i {
          font-size: 1.5vw;
        }

        .fa-angle-down {
          color: #41a5f0;
        }
      }
    }

    .collapse-content {
      background-color: rgb(226, 244, 255);
      padding-top: 1vw;
      border-radius: 0 0 5px 5px;
    }
  }


  .upcoming-res-options {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1vw 0;

    .show {
      font-size: clamp(14px, 1.4vw, 18px);
      margin-right: 2vw;
    }

    .btn {
      margin-right: 1vw;
    }

    .upcoming-res {
      overflow-y: auto;
    }


  }

  .available-slot {
    margin: 0;
    padding: 0;
    list-style-type: none;

    button {
      width: 100%;
      border: #cccccc54 solid 1px;
      border-radius: 5px;
      background: #EEE;
      box-shadow: none;
      margin-bottom: .5vw;

      span {
        display: block;
      }

      .res-group {
        width: 100%;
        display: grid;
        justify-content: space-between;
        grid-template-areas: "covers   standing-space  time    time-diff"
                             "tables     tables       tables    tables";

        .covers,
        .standing-space,
        .time,
        .time-diff,
        .tables,
        .fa-clock {
          height: 100%;
          font-size: 1.1vw;
        }

        .covers {
          grid-area: covers;
          margin-bottom: .5vw;
        }

        .standing-space {
          grid-area: standing-space;
          margin-bottom: .5vw;

          i {
            margin-right: .5vw;
          }

          .fa-male {
            font-size: 1.4vw;
          }

          .fa-check {
            color: #0ead0e;
          }

          .fa-times {
            color: #ff0000;
          }
        }

        .time {
          grid-area: time;
          margin-bottom: .5vw;
        }

        .time-diff {
          grid-area: time-diff;
          margin-bottom: .5vw;

          i {
            margin-right: .3vw;
          }
        }

        .table {
          margin: 0;
          padding: .2vw .5vw;
          width: auto;
          letter-spacing: 2px;
        }

        .tables {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          height: auto;
          background: #41a5f0;
          color: #FFF;
          border-radius: 3px;
          padding: clamp(5px, .5vw, 7px) 0;
          letter-spacing: 1px;
          grid-area: tables;
          white-space: normal;

          &.status-1 {
            background: #75af57bf;
          }

          &.status-3, &.status-0 {
            background: #f2bd71
          }
        }
      }

      .pbr {
        color: red;
      }

      .res-content {
        display: flex;
        flex-direction: column;

        .name {
          padding: .4vw .5vw;
          margin: 1vw 0;
          border-bottom: 1px solid #80808078;
          border-radius: 5px 5px 0 0;
          font-weight: bold;

          span {
            font-size: clamp(12px, 1.2vw, 20px);
            white-space: normal;
          }
        }

        .pre-order {
          display: flex;
          flex-direction: column;
          text-align: start;

          span {
            white-space: normal;
            font-size: clamp(11px, 1.2vw, 18px);
            margin-bottom: .2vw;
          }

          span:not(:last-child) {
            border-bottom: #ccccccc2 solid 1px;

          }
        }
      }
    }
  }
}